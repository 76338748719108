import React from 'react';
import Icon from '../../styles/atoms/icons';

import Section from '../../molecules/Section/Section';
import CallToAction from '../../molecules/CallToAction/CallToAction';

import {
  sectionStyles,
  wrapperStyles,
  Text,
  List,
  ListItem,
} from './styles/index.styled';

const LinkList = ({ component: { theme, heading, subhead, links } }) => (
  <Section style={sectionStyles(theme)} innerStyle={wrapperStyles}>
    <Text theme={theme}>
      {heading && <h2 className="heading">{heading}</h2>}
      {subhead && <p className="subhead">{subhead}</p>}
    </Text>
    {links && links?.length > 0 ? (
      <List>
        {links.map(link => (
          <ListItem key={link?.id} theme={theme}>
            <CallToAction
              link={link?.url}
              variant="linkColor"
              value={link?.label}
              icon={<Icon id="arrow-right" />}
              className="list-link"
            />
          </ListItem>
        ))}
      </List>
    ) : null}
  </Section>
);

export default LinkList;
